import { useCallback } from "react";
import { Alert, message, Modal } from "antd";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import store from "store";
import { useQueryClient } from "react-query";
import { useSingleLogin, useVerifyGoogleToken } from "queries/user";
import { hideLoginModal } from "redux/loginSlice";
import Form from "components/apps/Login/Form";
import { setSavedEmail, getSavedEmail, setToken } from "utils/cookies";

import { findHrCompanies } from "utils/helper";

const LoginModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const customOptions = { withSetEndpoint: true, withSetEmail: false };
  const mutation = useSingleLogin(customOptions);

  const verifyGoogleToken = useVerifyGoogleToken(customOptions);
  const { isLoading: loadingLoginGoogle } = verifyGoogleToken;

  const hideHandler = useCallback(() => dispatch(hideLoginModal()), [dispatch]);

  const successLoginCallback = useCallback(
    (data) => {
      const { companies, user, init, data: loginData } = data;
      // Setkan ulang baseURL nya untuk menangani jika endpoint berbeda
      // Axios.defaults.baseURL = generateBaseUri();
      // Jika user sama cuman di revalidate queries saja
      if (getSavedEmail() === user.email) {
        setToken(loginData?.access_token);
        store.set("app.endpoint", init?.endpoint);
        queryClient.invalidateQueries();
      } else {
        setSavedEmail(user.email);
        // Jika endpoint berbeda, dicek dulu user companies nya,
        const hrCompanies = findHrCompanies(companies);
        const countHrCompanies = hrCompanies.length;
        // Jika companies 1 set endpoint nya dan revalidate queries
        if (countHrCompanies === 1) {
          queryClient.invalidateQueries();
        } else if (countHrCompanies > 1) {
          // jika companies lebih dari satu di redirect ke pilih perusahaan
          store.remove("app.endpoint");
          navigate("/user/select-company");
        } else {
          store.remove("app.endpoint");
          // Jika tidak mempunyai perusahaan di redirect ke tambah perusahaan
          navigate("/company/add?from_login=1");
        }
      }
    },
    [navigate, queryClient]
  );

  const submitHandler = useCallback(
    async (values) => {
      try {
        const res = await mutation.mutateAsync(values);
        if (res.data.success) {
          successLoginCallback(res.data.data, values);
        }
      } catch (error) {
        message.error(error?.data?.message || "Something wrong...");
      }
    },
    [mutation, successLoginCallback]
  );

  const onGoogleLogin = useCallback(
    async (payload) => {
      try {
        if (payload?.code) {
          const res = await verifyGoogleToken.mutateAsync({
            code: payload.code,
          });
          if (res.data.success) {
            successLoginCallback(res.data.data);
          }
        }
      } catch (error) {
        message.error(error?.data?.message || "Something wrong...");
      }
    },
    [successLoginCallback, verifyGoogleToken]
  );

  return (
    <Modal
      open
      onCancel={hideHandler}
      footer={null}
      closable={false}
      maskClosable={false}
      destroyOnClose
      maskTransitionName=""
    >
      <Alert
        message={t("users.you_have_logged_out_automatically")}
        type="warning"
        className="uppercase font-bold mb-5"
      />
      {verifyGoogleToken.isError && (
        <Alert
          message={
            verifyGoogleToken.error?.data?.message ||
            t("users.email_is_not_registered_with_gajihub")
          }
          type="error"
          className="mb-5"
        />
      )}
      <Form
        onSubmit={submitHandler}
        onGoogleLogin={onGoogleLogin}
        loading={mutation.isLoading || loadingLoginGoogle}
      />
    </Modal>
  );
};

export default LoginModal;
