import { forwardRef, useMemo } from "react";
import { Form, Select, Spin } from "antd";
import _ from "lodash";

const { Option } = Select;

const CustomSuggestion = ({
  innerRef,
  id,
  name,
  search,
  placeholder,
  formItemProps,
  onSelect,
  onSearch,
  defaultValue,
  defaultOptions,
  includeOptions,
  excludeOptions,
  sourceData,
  data,
  isFetchingNextPage,
  fetchNextPage,
  loading,
  isInfiniteScroll,
  containerProps,
  ...props
}) => {
  const options = useMemo(() => {
    const options = [];
    let newData = _.cloneDeep(data);
    if (!_.isEmpty(defaultOptions)) {
      const filterDefault = (defaultOptions || [])?.filter(
        (item) => !newData.some((row) => item?.id === row?.id)
      );
      newData = [...filterDefault, ...newData];
    }
    if (!!defaultValue) {
      const findDefault = newData.find((e) => e.id === defaultValue);
      options.push({
        ...findDefault,
        name: findDefault?.name,
      });
    }
    if (!_.isEmpty(excludeOptions)) {
      newData = newData.filter((row) => !_.includes(excludeOptions, row.id));
    }
    // set option from default item, usally for update view, add option, filter
    options.forEach((row) => {
      const findItem = newData.find((item) => row.id === item.id);
      if (!findItem) {
        newData.unshift(row);
      }
    });
    return newData;
  }, [data, defaultValue, excludeOptions, defaultOptions]);

  const onScroll = (event) => {
    if (!sourceData) {
      var target = event?.target;
      if (
        !isFetchingNextPage &&
        Math.ceil(target?.scrollTop + target?.offsetHeight) ===
          target?.scrollHeight
      ) {
        if (!!fetchNextPage && typeof fetchNextPage === "function") {
          fetchNextPage();
        }
        target.scrollTo(0, target?.scrollHeight);
      }
    }
    return false;
  };

  return (
    <>
      <div key={`_${id}`} id={`custom-suggestion-${id}`} {...containerProps}>
        <Form.Item name={name} {...formItemProps}>
          {isInfiniteScroll ? (
            <Select
              filterOption={false}
              placeholder={placeholder}
              onFocus={() => onSearch("")}
              loading={loading}
              onPopupScroll={onScroll}
              searchValue={search}
              onSearch={onSearch}
              onSelect={onSelect}
              showSearch
              {...props}
            >
              {(options || []).map((item, index) => (
                <Option key={index} value={item.id}>
                  {item?.name || "-"}
                </Option>
              ))}
              {isFetchingNextPage || loading ? (
                <Option key={"loading"} disabled value="">
                  <div className="flex w-full items-center justify-center">
                    <Spin size="small" />
                  </div>
                </Option>
              ) : null}
            </Select>
          ) : (
            <Select
              placeholder={placeholder}
              loading={loading}
              onSelect={onSelect}
              showSearch
              options={(options || []).map((option) => ({
                value: option?.id,
                label: option?.name,
              }))}
              filterOption={(input, option) =>
                ((option?.label || "")?.toLocaleLowerCase() ?? "").includes(
                  (input || "")?.toLocaleLowerCase()
                )
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              {...props}
            />
          )}
        </Form.Item>
      </div>
    </>
  );
};

CustomSuggestion.defaultProps = {
  isInfiniteScroll: false,
  onSelect: () => {},
  data: [],
};

export default forwardRef((props, ref) => (
  <CustomSuggestion innerRef={ref} {...props} />
));
